export const validatorPositive = value => {
  if (value >= 0) {
    return true
  }
  return false
}

export const validatorPassword = password => {
  /* eslint-disable no-useless-escape */
  const regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()++--??]).{8,}/
  /* eslint-enable no-useless-escape */
  const validPassword = regExp.test(password)
  return validPassword
}

export const validatorCelular = celular => {
  /* eslint-disable no-useless-escape */
  const regExp = /^\([0-9]{2}\) [0-9]{5}-[0-9]{4}$/
  /* eslint-enable no-useless-escape */
  const validCelular = regExp.test(celular)
  return validCelular
}

export const validatorFixo = telFixo => {
  /* eslint-disable no-useless-escape */
  const regExp = /(\(?\d{2}\)?\s)?(\d{4,5}\-\d{4})$/
  /* eslint-enable no-useless-escape */
  const validTelFixo = regExp.test(telFixo)
  return validTelFixo
}

export const validatorCreditCard = creditnum => {
  /* eslint-disable no-useless-escape */
  const cRegExp = /^(?:3[47][0-9]{13})$/
  /* eslint-enable no-useless-escape */
  const validCreditCard = cRegExp.test(creditnum)
  return validCreditCard
}

export const validatorUrlValidator = val => {
  if (val === undefined || val === null || val.length === 0) {
    return true
  }
  /* eslint-disable no-useless-escape */
  const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
  /* eslint-enable no-useless-escape */
  return re.test(val)
}
